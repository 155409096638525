<template>
    <div class="tratamento-content">
        <div class="row">
            <div class="col-md-12">
                <v-table>
                    <tbody>
                        <tr>
                            <td class="bg-gradient-light text-dark text-center"
                                style="border-radius: 3px; padding: 2px 20px;">
                                Não há histórico a ser exibido.
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </div>
            <!-- <div class="col-md-4">
          <profile-card />
        </div> -->
        </div>
    </div>
</template>

<style></style>

<script>

export default {
    name: "Historico",
    data() {
        return {
        }
    },
    methods: {
    },
    components: {
    },

    mounted() {
    },
    beforeMount() {
    },
    beforeUnmount() {
    }
};
</script>